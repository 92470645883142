import '../sass/pages/ventilation.scoped.scss';
import React from 'react';
import Seo from '../components/Seo';
import Layout from '../components/blocks/Layout';
import { StaticImage } from 'gatsby-plugin-image';
import { ModalProvider } from '../context/ModalContext';

const VentilationWrapper = () => {
  return (
    <ModalProvider>
      <Ventilation />
    </ModalProvider>
  );
};

const Ventilation = () => {
  return (
    <Layout>
      <Seo title='Вентиляция' />
      <h1>Вентиляция</h1>
      <div className='content'>
        <p className='lead'>
          <span>
            ТОО «Invest Group Company» занимается{' '}
            <strong>изготовлением и продажей</strong> большого количества{' '}
            <strong>вентиляционного оборудования</strong> и изделий, и их{' '}
            <strong>монтаж</strong>.
          </span>
          <span>
            Также в наличии оборудование всех известных брендов по{' '}
            <strong>ценам производителя</strong>.
          </span>
        </p>
        <div className='categories'>
          <div className='category'>
            <div className='category-img'>
              <StaticImage
                objectFit='contain'
                placeholder='none'
                src='../images/vents/air-handling-units.png'
                alt='Приточно-вытяжная установка'
              />
            </div>
            <div className='category-info'>
              <h2 className='category-name'>Приточно-вытяжные установки</h2>
              <ul className='text-list'>
                <li>
                  Приточные установки с электрическим и с водяным нагревом
                </li>
                <li>Приточные установки с рекуперацией тепла</li>
                <li>Компактные приточные установки</li>
                <li>Каркасно-панельные установки</li>
                <li>Канальные вентиляционные агрегаты</li>
              </ul>
            </div>
          </div>
          <div className='category'>
            <div className='category-img'>
              <StaticImage
                objectFit='contain'
                placeholder='none'
                src='../images/vents/fans.png'
                alt='Вентилятор'
              />
            </div>
            <div className='category-info'>
              <h2 className='category-name'>Вентиляторы</h2>
              <ul className='text-list'>
                <li>Канальные</li>
                <li>Радиальные</li>
                <li>Крышные</li>
                <li>Осевые</li>
                <li>Вентиляторы дымоудаления</li>
                <li>Вентиляционные установки</li>
              </ul>
            </div>
          </div>
          <div className='category'>
            <div className='category-img'>
              <StaticImage
                objectFit='contain'
                placeholder='none'
                src='../images/vents/air-ducts-and-fittings.png'
                alt='Воздуховоды и фасонные изделия'
              />
            </div>
            <div className='category-info'>
              <h2 className='category-name'>Воздуховоды и фасонные изделия</h2>
              <ul className='text-list'>
                <li>Из оцинкованной стали</li>
                <li>С полимерным покрытием</li>
                <li>Из тонколистовой стали</li>
                <li>Из кровельной стали</li>
                <li>Из нержавеющей стали</li>
                <li>Гофрированные</li>
                <li>Гибкие воздуховоды</li>
                <li>Дымоходы</li>
              </ul>
            </div>
          </div>
          <div className='category'>
            <div className='category-img'>
              <StaticImage
                objectFit='contain'
                placeholder='none'
                src='../images/vents/details-of-ventilation-systems.png'
                alt='Детали систем вентиляции'
              />
            </div>
            <div className='category-info'>
              <h2 className='category-name'>Детали систем вентиляции</h2>
              <ul className='text-list'>
                <li>Зонты вентиляционные</li>
                <li>Шумоглушители</li>
                <li>Пластины шумоглушащие</li>
                <li>Дефлекторы</li>
                <li>Узлы прохода</li>
                <li>Эжекторы низкого давления</li>
                <li>Обратные клапаны</li>
                <li>Лючки для прочистки воздуховодов</li>
              </ul>
            </div>
          </div>
          <div className='category'>
            <div className='category-img'>
              <StaticImage
                objectFit='contain'
                placeholder='none'
                src='../images/vents/smoke-extraction-and-fire-protection.png'
                alt='Дымоудаление и огнезащита'
              />
            </div>
            <div className='category-info'>
              <h2 className='category-name'>Дымоудаление и огнезащита</h2>
              <ul className='text-list'>
                <li>Клапаны дымоудаления ДМУ</li>
                <li>Клапаны дымоудаления ДМУ МС многостворчатые</li>
                <li>Клапаны противопожарные типа ОЗ</li>
                <li>Клапаны противопожарные типа ОЗ МС многостворчатые</li>
              </ul>
            </div>
          </div>
          <div className='category'>
            <div className='category-img'>
              <StaticImage
                objectFit='contain'
                placeholder='none'
                src='../images/vents/air-distributors.png'
                alt='Воздухораспределители'
              />
            </div>
            <div className='category-info'>
              <h2 className='category-name'>Воздухораспределители</h2>
              <ul className='text-list'>
                <li>Диффузоры</li>
                <li>Анемостаты</li>
                <li>Клапаны и заслонки</li>
                <li>Фильтры и циклоны</li>
                <li>Решетки</li>
                <li>Электроприводы</li>
                <li>Рекуператоры</li>
              </ul>
            </div>
          </div>
          <div className='category'>
            <div className='category-img'>
              <StaticImage
                objectFit='contain'
                placeholder='none'
                src='../images/vents/thermal-ventilation-equipment.png'
                alt='Тепловентиляционное оборудование'
              />
            </div>
            <div className='category-info'>
              <h2 className='category-name'>
                Тепловентиляционное оборудование
              </h2>
              <ul className='text-list'>
                <li>Электрические воздухонагреватели</li>
                <li>Воздухоохладители</li>
                <li>Калориферы</li>
                <li>Нагреватели</li>
              </ul>
            </div>
          </div>
        </div>
        <section className='text-section'>
          <h2 className='section-heading'>
            Этапы выполнения монтажа вентиляционных систем
          </h2>
          <p>
            Первым этапом любого проекта является бесплатный выезд специалиста
            на объект и знакомство с клиентом. Далее происходит сбор исходной
            информации, натурные измерения и определение пожеланий клиента, на
            основании которых составляется коммерческое предложение и
            техническое задание.
          </p>
          <p>
            После согласования технического задания специалисты ТОО «Invest
            Group Company» приступают к разработке проектной документации
            системы вентиляции, которая выполняется согласно действующим
            строительным номам Республики Казахстан.
          </p>
          <p>
            Последним этапом является непосредственно монтаж системы вентиляции,
            который условно можно разбить на несколько подпунктов:
          </p>
          <div className='list'>
            <ul>
              <li>
                Подготовительный. Поставка оборудования, воздуховодов, расходных
                элементов.
              </li>
              <li>
                Монтажный. Прокладка вентиляции, сборка воздуховодов и всех
                элементов конструкции.
              </li>
              <li>Проверочный. Выполнение пуско-наладочных работ.</li>
              <li>Финальный. Сдача готовой системы в эксплуатацию.</li>
            </ul>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default VentilationWrapper;
